import axios from 'axios';
import * as bekit from 'bekit';
import { ls } from './ls.js';
const { Url } = bekit.helper;
import store from '@/store';
// import qs from 'qs';

axios.defaults.baseURL = '/api';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['platform'] = 'web';
axios.defaults.headers.common['selLang'] = 'en';
axios.defaults.headers.common['v'] = '1.0.0';
axios.interceptors.request.use(config => {
    // 跨域时不作处理
    if (/^http/i.test(config.url)) return config;

    config.params = Object.assign({}, config.params);
    config.timeout = 1000 * 10;

    if (config.method == 'POST' || config.method == 'post') {
        // config.data = qs.stringify(config.data);
    }

    // 默认是有loading message默认为布尔true
    config.loading = config.loading === undefined ? { show: true, message: 'Loading…' } : config.loading;
    config.loading.show && store.commit('addRequestLoading', config.loading.message);
    return config;
}, error => {
    bekit.notice.alert('', 'Error, Please try again');
    return Promise.reject(error);
});


axios.interceptors.response.use((res) => {
    res.config.loading.show && store.commit('reduceRequestLoading');
    if (res.data.code) bekit.notice.toast(res.data.msg);
    switch (res.data.code) {
        case 0:
            return Promise.resolve(res.data.data);
            break;
        default:
            break;
    }
    return Promise.reject({
        type: 'api',
        body: res.data,
    });
}, (error) => {
    error.config.loading.show && store.commit('reduceRequestLoading');
    if (!(error.request.readyState == 4 && error.request.status == 0)) {
        bekit.notice.alert('', 'Network error, please try later');
    }
    return Promise.reject({
        type: 'network'
    });
});

function request(httpOptions, suc, fail) {
    return axios.request(httpOptions).then(suc).catch(err => {
        fail && typeof fail === 'function' && fail(err);
    });
}

request.install = function(Vue) {
    Vue.prototype.$request = request;
};

export default request;