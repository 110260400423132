const books = [{
    title: 'His Reluctant Luna',
    author: 'N Chandra',
    cover: 'https://img.moonshadowreader.com/book-cover/81515473552_1620204859.png',
    introduction: `"What are you doing?" I shouted as the huge man ripped off my engagement ring from my finger.
"No!" I screamed as he threw away the diamond ring.
"You are mine! Do you understand? Only mine!" His gorgeous green eyes bore into mine.
"Never! I reject..... " I never completed the sentence, his mouth was on mine, capturing my lips into a punishing kiss.
Alice has her life all sorted, she has a good job, a wonderful fiancee. Apart from a little secret, she was a werewolf, her life was in her control. After losing her parents in a rough attack she had been sent away to live with her aunt, a keeper.
Alice was what one would call an urban werewolf who had no intention of finding her mate.
But things didn't turn out as expected when the most handsome, powerful and dangerous Alpha of US claimed her as his mate. With her wolf in the heat but her mind warning her against the union.
How will she resist being his Luna?"
    `,
    firstChpater: `"The loud beeping of my phone roused me from sleep, I shut the alarm and snuggled under the covers for few more minutes.
I usually take time in waking up, so I have set an early alarm. I finally dragged myself out of the bed and quickly got into my daily routine. I am an architect, at 24, I am one of the youngest employees of Barton & Tom one of the leading Construction firms in London.
Grabbing my coffee, I walked onto the train. I always keep my headphones on because I can hear everything. I mean literally, everything. I have extraordinary senses because I am not normal. I have extraordinary speed, agility, and strength. 
No, I am not some superhero, I am a werewolf, however, unlike most werewolves, I do not live in a pack. No, I am not a rogue wolf either.
I belong to one of the most powerful packs of North America, 'The Jade moon pack'. I am very much in touch with my older brother the Alpha of the pack Rick Anderson and the pack Beta, Bobby. My father's beta Thomas Jackson, and his lovely wife Martha. I even visit my pack annually during Christmas.
However, I prefer living independently. Rather, I have got used to this lifestyle. There is a reason why I live away.
When I was 9, I had accompanied my parents to New York City for some inter-werewolf conference, I was overly excited about the trip. As we were staying right in front of the central park. But things did not turn out as expected. I do not remember much but my father died trying to protect me from the rogues and other packs. Werewolves are violent and territorial. They fight over the littlest of things. My father died in a were war.
My mother died soon after due to a broken heart. They were mates. That is one of the reasons I do not want to find my mate. The mated wolves are like two halves of a soul. They either die or turn mad with grief if their mate is lost. Very few wolves manage to survive the ordeal of losing their mates.
I do not want to be one of them. Though my wolf has been searching for her mate ever since I hit puberty and shifted for the first time.
After my mother's death, I was sent off to Australia, to live with my aunt. Aunt Flora is a keeper. Keepers are humans who are aware of werewolves and other supernatural beings. You see, werewolves are very secretive and stay hidden from humans.
There might be a werewolf living right next to you, and you would never know it. However, living among humans require some amount of human interactions for example tax filing, property dealings and various legal stuff.
Keepers are such humans who know about us and help us. My mother was also a keeper until she met my father and they discovered they were mates.
I did not live in Australia for long, as soon I shifted for the first time, my aunt moved to Europe. You see there are no wolves in Australia, hence no werewolf packs. If I were accidentally spotted by someone while out for a run, it would be hard to explain the presence of a wolf in the country.
Though even the UK does not have any wild wolves. They were all hunted down by the end of the 17th century. 
Most of the werewolf packs migrated to the New World to avoid prosecution back in Europe. Hence, very few packs remained in Europe and none in the UK. They thrived in North America, soon adapting to the vast tracks of land and the wildernesses.
I have got control of my wolf now. I rarely shift unless I am in total isolation. I kind of like being human.
"Alice! How was your weekend?"  Elsa my pretty redhead colleague asked me as soon as I had reached my cubicle.
"Nothing much, I visited aunt Flora, how about you?" I asked while shoving my bag in the drawer.
"Well, just usual clubbing.  I missed you, you should join me next week with Mark of course."
Elsa grinned at me. I just laughed it off. Mark Barton the heir to the Barton & Tom is my boyfriend.
"When is he coming back from his business trip?" 
"Middle of this week hopefully," I replied.
Mark and I have been together for the last two years, he hit on me right after I joined his company. I am used to getting male attention. I am not tall, I am an average 5'4 tall, but I have a slim body, dark hair and hazel eyes. Everyone says I look like my mother. She was very pretty, perhaps, I did inherit some of her good looks.
Initially, I avoided Mark because dating the heir of the company I worked for would have been unprofessional. 
But Mark was very persuasive, not to mention sweet, and kind. Unlike wolves who are very protective and possessive. I cannot stand obsessive partners. Another reason why I do not want a mate!
Mark is a human and like everyone else in the office and my friend circle, he has no idea that I am a werewolf. I have successfully kept my wolf concealed all these years and I plan to keep it that way. However, someday I would have to reveal my true nature to him. I do not know how he would react. I have been avoiding that situation so far.
The day went on uneventfully, boring to be precise. I like boring because my life back in the US was extraordinary. 
I had just entered my 1 bedroom flat when my phone rang. It was Emma, Beta Thomas's daughter and one of my best friends. 
I turned on the video call and smiled at her. Like most werewolves of our age, she had found her mate. Bobby her mate was my brother's Beta.
"Hi, Emma, what's up?" I asked.
Emma with her golden locks smiled from the other side.
"I can't wait to meet you. You are coming for the Christmas vacation, right?"
"Only if you promise not to force me to meet other alphas," I replied.
Emma and everyone else who were close to me did not approve of my human boyfriend. Not because he was a human, but because he was not my mate. For werewolves finding their mate is the most crucial aspect of their life. They were baffled by the fact that I was disinterested in finding my mate.
It is hard to explain. I just do not want to end up like my mother or that mad wolf in my pack who killed himself after losing his mate.
She sighed," I know you don't want to find your mate, but you can't fight fate. If something has to happen it will happen. Ok?"
"Yeah, whatever," I rolled my eyes.
"So how are things back home?" I asked while collapsing on the sofa.
"Your brother is being an ass as usual. He has increased the training routine for all the young wolves. So, they are all crabby these days."
"Sounds like Rick," I chuckled.
My brother had to take over the pack at the age of 17, after my father's death. Rick is eight years older than me. He was lucky to find his mate Linda at the age of 21. She is the sweetest person ever and helped him find balance and peace in his life. 
I finished my conversation with Emma and took off my clothes to prepare for the night.
I shifted into my brown wolf, Lana. I cannot go out in the city for a run, but my wolf understands. I let her run around and have fun at aunt Flora's estate. Curling onto my bed I slept off in my wolf form."
    `
}, {
    title: 'Awakening Rejected Mate',
    author: 'L.T.Marshall',
    cover: 'https://img.moonshadowreader.com/book-cover/85758165734_1620134524.png',
    introduction: `"Alora Dennison is an orphaned child from a shamed bloodline surviving in her families old pack. On the dawn of her transition pushing her into adulthood she imprints on the mate she will be bonded to for an eternity, in an unexpected turn of fate.
Only he isn't the man of her dreams. He is the only one in the entire state she would never have wanted to bond too.
Colton Santo is the arrogant, dominant son of the Alpha from a rival pack which is set to unite the packs and reign in one kingdom. In years gone by his disdain for her and any from her bloodline has been prominent. Her treatment by his pack has pushed her to live in near isolation, fearful for her existence and now before all assembled, on the dawn of her awakening, they all just saw her imprint on their future leader.
Fate has decreed it, but everyone around her is about to try and stop it.
Fate isn't about to make it easy on her either, as a long forgotten war erupts in their lands, bringing an age old enemy with a thirst for blood back into the forefront of lycanthrope life.
Will she survive long enough to ever find out why she has borne a black mark on her lineage her entire life? And why exactly, Colton's father is just so eager to see her dead.
Will Colton step up and honour the bond, or will he be the one to deliver the final blow?"`,
    firstChpater: `"You know those stories about unwanted rejects, whose loved ones either died or all abandoned them to drift aimlessly through the world? A worthless no one, almost invisible to other people. Nothing spectacular about them, no grand rise from nothing to something as they dawn into adulthood.
Yeah well, that's kind of my story.
My name is Alora Dennison and I am literally hours away from my Awakening Ceremony at the ripe old age of 18 years old, with absolutely no one who gives a crap about me to be there, no support, no family, and definitely no friends. I'm a late bloomer, I guess. Not that it's unusual in my bloodline, as almost every female in my family didn't 'come out' until they hit their late teens from what I can remember. Much like the others in the orphanage, stuck here with me. Another reason we are snubbed and left to our own devices in this hell hole they call a home.
My ceremony has me all churned up inside and restlessly pacing the room I share with Vanka. She's like me, although in all the years we've bunked together I can't say we have ever become friends of any kind. She makes it clear she doesn't like me and much like everyone around me, they all keep their distance. We tolerate one another, but none of us have ever bonded.
I'm an orphaned no-one whose parents died in the war of ten years ago against the vampires. So is she, but it didn't warm her to me in anyway. I guess because she’s from a leg of the Santo pack and they have hated the Whyte pack since long before the war. We were feuding before the vampires united every one of us and old scars and grudges are not something that wolves let go easily.
We were just two small girls left with no immediate blood link guardians, put in this place for unwanted cubs, to live out our days in unexceptional ways. It would have been kinder to end our misery back then, than leave us to live as outcasts among our own people, our own kind. Shunned because we are the shameful proof that their own packs failed them. I don't think they knew what else to do with us. So many young with no one left to care for them and raise them in our ways and seen as cursed. They were ashamed of the failings of our families and we are the ones to carry that burden like an eternal black mark painted on our faces.
I’m amazed that kids like us even get to go through with the ceremony, I mean it's kind of a big deal and we are kind of not. We're a bit like the lost boys in Neverland, except.... none of us want to stay here and growing up is the only way out.
Your Awakening is a bit like graduation in a sense. A passing from child to adult and normally where you would find your place, your rank, in the pack, and get a mate. I have no delusions that it means anything of the sort for any of us turning tonight. Of which there are four from the home of unwanteds, and I hear maybe three from the packs around. Just a handful of kids trying to break free, find their path, and all in the great presence of the entire ’Packdom¹.
The Packdom being the dozen or so wolf tribes from the state. They all have to convene on the shadow rock to watch you transform fully for the first time, under the first full moon of your birth month. It's not hard to figure out when you' re ready. The weeks running up to your birthday, you start to change in small ways and, god damn, it hurts. Like having your insides snapped and stretched in fits of severe twisting pain and zero control over it happening.
The signs are pretty evident to all. Kind of like puberty for werewolves, I guess. Maturing, physical improvements and a massive rise in appetite and aggression. Little moments where you start to transform painfully and then it dissipates just as quickly so you never really reach a first transition, but it's reported, and no one can hide it. That pain, that you know will come with the first time, it’s saved for the full moon after your birthday.
Some don't go through it until later in life, and some earlier. Usually, when you turn, is a sign of where you stand in the hierarchy. The longer it takes, the weaker your DNA, according to the Santo elders anyway but my parents never mentioned any of this when I was young. So, being eighteen, puts me way down on the pecking order and just confirms my bloodline were not that of warriors or strong enough to be anything of importance. Vanka is sixteen and she too is turning tonight, but with Santo blood somewhere in her veins, she should have turned far earlier. I guess whatever mix is in her is the reason they reject her as one of their own.
I mean look at the Santo's, they are the reigning pack in the state and everyone in their bloodline turned before the age of ten. Colton, the next heir as Alpha, is nineteen years old, lords over all in our kingdom and he has been running with the pack since he was a mere eight years old.
Every single one of his family returned from the wars, which speaks volumes to the purity of their genes, their strength, and their abilities in battle. He's destined to take over from his father as Alpha one day and the way things are heading, he won't just be Alpha of the Santo pack, but all of us. Something that has never happened in our lifetime but will begin a new dawn in how the packs live.
Santo is not a nice guy, none of them are. He walks around surrounded by his sub pack, looks down at the likes of us and never makes eye contact or responds to anyone below his station. That's how it works here, dominance and strength is everything to wolves. He has his father's arrogance and he knows every female hitting puberty is craving to become his mate. He hasn't officially paired or marked yet and despite having the same girl always by his side, he's fair game until he does.
Faultlessly good looking in that Latino, pretty boy kind of way with far too handsome a face. He's over six feet of muscle and radiates aggression without trying and is a rare black furred wolf on turning, one of the largest among us. I think the one time he actually acknowledged my existence was the day he pushed me out of his way in passing. I tripped in front of him in the corridor to the great hall, and he didn't bat an eye or miss a step in shoving me back aggressively, like I was a lightweight piece of trash. All the girls laughed at me when I landed on my ass and skidded back into the trashcan and I've made sure to never get in his way again.
Not that we have much time in the same place. I live in the orphanage and go to the school nearby that was built purely for our kind, away from 'normal' people. He was ahead of me by one year, so we didn't really cross paths in all that time, and since he lives with his pack on the south side of the mountain, only coming to the shadowy north when required, I never see him or any of his subordinates. Like all the rest of the people who avoid the 'Rejects’.
Our people all moved and convened nearer the mountain from all surrounding areas after the great war. Keeping close to stay protected and no one ever left again. His father is the unofficial Dominant Alpha and likes to check in with all on the mountain when he sees fit. Since Colton graduated school, we only see him when by his father's side for official visits. Lording over their newfound kingdom of obedient and submissive packs, keeping law and order.
Rumor has it the vampires have been brewing and gathering for several months, maybe even years, to regain numbers and launch a new war on our kind. We always knew they would. I mean we won the war, but we didn't defeat them in the way we wanted. Many survived and fled and have been out there for almost ten years, recovering from it and licking their wounds. It's been quiet for so long, eerily so, but there is so much unease and unrest in the air that the packs called together a meeting a month back to decide the fate of our future. Trouble stirring and we could all feel it, our senses on high alert and that vibe that something huge is coming. They think a coming together to create one pack and one unity is the answer to a brewing war. Not that it changes much, as we have been living almost that way for a decade.
We were never united before under just one Alpha though. We fought as separate packs and it almost wiped us out. There was no leadership as a whole and it meant packs like mine, known for peaceful living and farming, were almost annihilated. Many of our kin never returned and those who did, were forever changed. Those like me who lost everyone; my parents, grandparents, uncles, and my brother... we are shunned by people who like to pretend it never happened. My family were lost, none of them came back and therefore in the eyes of the pack hierarchy ... my bloodline is weak. They don't want to claim us as their kind anymore and
they sure as hell don't want us procreating and spreading our genes to future wolves.
Warriors came home. The weak did not.
We were never ready for it."`
}, {
    title: 'Begin Again',
    author: 'Val Sims',
    cover: 'https://img.moonshadowreader.com/book-cover/86968363383_1620204032.jpg',
    introduction: `A hot rebound is just what any doctor would recommend for Eden McBride's broken heart after a brutal breakup. Not really. But it's what she needs. Liam Anderson is the perfect rebound guy. Dubbed the Three Months Prince because he's never with the same girl longer than three months, Liam's had his share of one night stands and doesn't expect Eden to be anything more than a hookup.`,
    firstChpater: `"Can someone please tell me why I left our perfectly comfortable couch to freeze my ass off here?" Eden McBride glared at her three friends waiting patiently in the queue with her.
It's been over an hour, but the long line snaking its way around the block had barely moved.
Out of all the hangouts in Rock Castle, they had to choose Crush, one of the most challenging clubs to get into, especially on the one weekend when the hottest DJ in town makes an appearance.
"To help you get over the man whose name we won't mention!" Sienna, her best friend since primary school, said in a hushed tone. The clear plastic beads dangling on the ends of her long ombre braids chinked as she turned her head to match her death glare.
On her 'bad' days, Sienna was cute. But on a good day, like tonight, she was smoking hot. The guys milling about, desperate to get inside like they were, clearly thought so too. They could barely peel their eyes from her.
"Yeah, Eden, we've given you enough time to mope," Lydia chimed in as she snapped a quick selfie and posted it on her Instagram. Within seconds her phone pinged incessantly with notifications from millions of adoring fans. Lydia is a mega-successful YouTuber whose makeup videos have catapulted her to a goddess-like status on the internet.
"The sooner you get back on the bike, the better," Cassandra added, flicking her long blond hair over her shoulder as she pulled up the collar of her signature leather jacket. In the five or six years Eden's known her, she's never seen her in a dress. Not even once. For a self- proclaimed tomboy, Cassandra was effortlessly chic, and with her tall slim physique and delicate features, she could pull off any look.
In their crew, Eden was the plainest, and she was okay with that. Her skin was so pale she could never get a tan no matter how long she stayed in the sun. She tried colouring her long mousey brown hair a few times, but the constant retouching got old real quick. Her most striking feature was her slanted, brown eyes. Pity, she had to hide them behind thick-lensed glasses because she was almost as blind as a bat without them.
"He's moved on. You should do the same!" Lydia chimed in brutally. Subtlety was not her strong suit.
Eden sighed and rolled her eyes. Her friends meant well. But, she was okay with spending her days and nights in front of the TV binging on carbs and terrible reality shows. She was cool with not brushing her hair or changing her clothes for days on end. She was happy to cry herself to sleep and wake up with a puffy face and swollen eyes. But she didn't want to be rushed through her grief.
How could six weeks be enough to get over a lifetime of memories, of four years of happy moments and hopeful dreams, dashed in an instant?
"If this stupid line doesn't move in the next two minutes, I'm leaving," she hissed and pulled her trench tighter, glad she had the foresight to wear it even when her friends wanted her to ditch it because it was 'ruining her whole aesthetic'.
A Lamborghini screeched in front of the entrance, followed by a Ferrari and a Porsche. A group of men, as tall as the surrounding office towers and good looking enough to have walked straight out of a fashion magazine, jumped out of the three cars, threw their car keys at the valets, and made their way to the door.
Perhaps it was the long line that seemed to be going nowhere fast or the stress of the past few weeks, but when Eden saw the six towers trying to bypass the queue, she lost all her patience. Without thinking, she left her place and stormed to the entrance, her friends trailing behind her.
She tapped the very tall ginger, trying to smooth talk his way into the club, on the shoulder. He turned to look at her, his thick eyebrows fusing in a questioning frown.
Eden paused, her lungs struggling to keep up with her thoughts and take in simple breaths. With hair so bright like flames, she expected his eyes to be green. Not this denim blue. She could feel herself struggle against their pull.
"Eden, don't cause a scene," Sienna gritted her teeth and tugged at her arm.
But, Eden saw no reason to be polite. Not when she was almost frozen solid she could barely feel her ass.
She stretched to her full height as she tried to match the man's towering size. But even in her Jimmy Choo stilettos, she still had to look up at him.
"Can I help you?" He asked in a voice meant to melt the panties off of any woman within a kilometre radius.
As if he wasn't already deadly enough, he had a cleft too. The fact that it wasn't so prominent and only seemed to show itself when he spoke or smiled, which was all he did in the last fifty seconds, made it all the more devastating.
"I don't need your help," Eden said icily, hating him a little. He had no right to be so attractive.
"Okay, then!" He shrugged, showing off two rows of perfectly straight teeth as he smiled. They were so white she thought they might be veneers. They had to be. There was no way anyone would have such great teeth unless they had an excellent dentist.
"If you are done gawking at me-"
Eden held up her hand, irritated with herself for noticing all these things about him and hating him a little more for his presumptuous arrogance.
"Do you see all these people?" She glared at him and pointed at the endless line. "They've been waiting for over an hour. You can't just come here and skip the queue."
"Are you going to stop me, Princess?" His rust- coloured eyebrows shot up, his eyes sparkling with amusement and his Calvin Klein underwear model friends sniggered. Eden wanted so much to wipe the smirk off his face with her puny little fists. But she was an educated person. She didn't have to use her hands to prove her point. Words were just as powerful.
"If you have any decency, you'll do the right thing and wait in line like everyone else." She hissed, blinking furiously behind her black-framed glasses.
A hush fell over the small crowd gathered around them. Eden's friends kept tugging and pulling at her. But she was so over everything, including this night, and she refused to be intimidated by Red as he leaned down to stare at her at eye level condescendingly.
"I guess I'm not a decent person now, am I?" He blew a cold minty breath on her face and shrugged, returning his attention to the bouncer.
He flashed a few notes at the burly man, gathered up his crew, and waved at her group. "They are with us!"
Before Eden could even process his announcement, they were already inside the club, wading through a swarm of sweaty heaving bodies swaying to the music.
It took her eyes a few seconds to adjust to the dim lighting. Up ahead, she saw the man she just tussled with head to a VIP booth.
Was she supposed to thank him for getting them in? No way, she shook her head. Now that she was inside, she was glad her toes and ass were not so numb anymore, but she had no issue waiting her turn like everyone else.
"Oh, St. Eden, we are forever in your debt. Drinks are on us tonight!" Cassandra bowed and brought her hands up in a prayer- like gesture.
Lydia giggled and gushed. "Yeah, you took one for the team! I mean, I would never have dared to approach Liam."
"That's his name?" Eden asked, barely paying attention to her friends' chatter. 'Red' suited him better in her opinion.
She craned her neck, scanning the room for free seats. But other than a few empty stools at the bar, there was no sitting room anywhere, and she desperately wanted to sit down. As cute as her shoes were, especially when paired with her black midi dress, her feet were seriously killing her.
"Liam's like royalty around here. You must have heard about him," Sienna rattled away. "He's a motorsport driver, throws the craziest parties, and he has a three- month rule. He never dates anyone longer than three months."
'What a charming guy!" Eden nodded
absently, but she's never heard of him. Not surprising since she never paid attention to Rock Union's social scene.
Her eyes lit up when she saw some empty stools at the bar. It wasn't prime real estate, especially since all the already inebriated idiots seemed to gravitate there, but she had to rest her feet.
"Let's go," she grabbed Sienna's hand, and they pushed their way through the crowd, Cassandra and Lydia close behind them.
"First round's on me!" Lydia yelled over the music as she tried to catch the bartender's attention."`
}, {
    title: 'My Human Mate',
    author: 'MimiNeinei',
    cover: 'https://img.moonshadowreader.com/book-cover/82721131115_1617097783.jpg',
    introduction: `"He is arrogant.
He is powerful.
People around me call him Alpha. And he has a secret.
----------
Broken and betrayed, Ella Merlyn Scott moved to a small town with her mother to forget her past and move on. She transferred to the town's university, wherein she met him. Blaze Matthew Reese, a soon to be Alpha of the Blue Moon Pack an arrogant, conceited jerkface whom Ella hate. But everything changed when she discovered his secret."`,
    firstChpater: `"Ella glanced at the wall of her room for the last time. She picked the handle of her luggage and closed the door behind her. She went down the stairs, seeing her mother waiting for her patiently.
“Did you already fixed your things?” her mother asked. Ella smiled and gently nodded her head.
“I’m so sorry, my child,” her mother said.
“No, Mom. It’s totally fine with me, and I need this,” she answered. Her mother walked towards her and engulfed her in a tight hug.
“If only I could take away your pain, I will do it without a second thought,” her mother said. Ella hugged her back and quietly sobbed on the crook of her neck like a small child wanted to be babied. It was never in her option to move away with her mother. She planned to live together with her boyfriend, but it never happens.
Slowly, flashbacks of the night that broke her came back, haunting her. It was supposedly a fun night because it was her best friend’s birthday, but it turned out something she wasn’t expecting. She wanted to surprise her, but it was her who got surprised instead. Ella caught her boyfriend Ashton naked and doing nasty things with Jasmine in her room. It hurt her so much. She trusted those two in all her heart. It was when Ella decided to move away from the place that brought her pain and sadness.
“Go and put your things in the car. We will leave once I talked to the agent about our house,” her mother said. Once again, Ella nodded her head and walked where her mother parked their car. She carefully lifted her luggage and put it inside the car’s compartment.
“Ella,” the voice behind her spoke. She closed her eyes and clasped her chest. It’s the voice she never wanted to hear again. She mustered every ounce of courage she has and turned back.
“Ashton,” she coldly said.
“Babe, let me explain,” he begged and tried touching Ella, but she swats his hands away. Ashton reeks of strong alcohol. He was struggling to keep his balance steady. He looked at her, tears falling from his eyes.
“There’s nothing to explain, Ash. I saw what I saw,” she said and tried to bit back her cry. Seeing Ashton standing in front of her broke her heart again.
“Ella, please,” Ashton said. Ella couldn’t hold it any longer, and she slapped him hard on his face.
“Damn you! Why did you do this to me!?” Ella burst into anger, her chest rising up and down. Her hands were shaking and stinging in pain. “I trusted you and love with all my heart!” she shouted, her body wracked with an onslaught of sobs and tears.
“Babe, I’m sorry. It’s not what you think it is,” Ashton begged. Ella bitterly laughs. He still dared to lie in front of her face. She is not a fool. She saw them having sex in the filthy room.
“Really, Ash?” she said and wiped the tears on her face. Suddenly, a pink car parked just across the road, and Jasmine went out. She looked to her left and right before crossing the streets.
“Ella, please let us explain,” she said and held Ashton’s hand. Ella clenched her fist at the sight in front of her. It disgusts her so much. The friend she thought would support her was silently stabbing her in the back and snatched her boyfriend away.
 “There’s no need, Jasmine. He’s all yours,” she said and turned her back away from them.
“I’m sorry, Ella. I’m pregnant, and Ashton is the father,” Jasmine said and sobbed. “I’m sorry. I love him,” she added. Ashton was stuck on the ground. He couldn’t believe what Jasmine has said. Ella mumbled incoherent things through her hands and choked on her sobs. She couldn’t handle that pain and ran back inside, leaving Jasmine and Ashton.
Ella broke down while clasping her chest. She kneeled on the ground while crying her heart out. Her mother quickly went out of the kitchen, hearing her loud wails. She hugged Ella and kissed the top of her head, whispering words of comfort in her ear until she passed out.
Cars were speeding up ahead, and the honks were blaring pretty loud in the busy streets. Ella woke up with swollen eyes and a blanket covered in her body. She glanced outside the window. The sky was already ablaze with the fire of the setting sun. Ella stood up and went to the kitchen, where she thought her mother would be.
“Mom?” she called. Elisa – that was the name of the woman who raised her as she is right now. Elisa wiped her hands with the cloth and smile at her daughter.
“Did you had a good sleep?” Elisa asked. Ella shook her head as a response. Her head is aching like someone was hammering on it. She sat on the vacant chair and sipped the coffee that her mother gave.
“I thought we were leaving today?” she asked, a creased on her forehead was visible.
“The agent has to finalize some papers,” Elisa answered. “We’re probably driving to our new home tomorrow,” she added and sat beside her. Ella is ready to leave this place, along with her feelings and her shattered heart."`
}, {
    title: 'Taming FAYE',
    author: 'Stella Shee',
    cover: 'https://img.moonshadowreader.com/book-cover/85421225590_1616746431.jpg',
    introduction: `Warnings! This book is strictly R18+ . "Just one night stand with her new boss changed everything about her existence, the more Faye tries to run away, the more daylan was dead set on turning her world upside down because whatever Daylan Sage wants he gets, and he wants her Willingly or by Force".`,
    firstChpater: `"He became a successful billionaire at 30, tall handsome and with a great body.
Daylan Sage is a genius in the business world, a goal oriented man who single handedly picked his father's business from the slump of bankruptcy to become the top leading company in the country with branches almost all countries.
His late father lost everything when he married his step mother after the death of his biological mother to cancer.
His father wealth started to swindle down the day Ameerah the most selfish and wasteful woman on Earth came into hiis life.
After his dad died, he venture into the business world, with his consistency and focus he became a billionaire in no time, making his father shabby business an empire.
He was and is still the CEO of Sage Family Group Of Company.
Everything about Daylan was hectic, his schedule doesn't allow him to mingle with people not even with his best friend Anthony or for him to find love again.
Not after he caught his Ex girlfrend Jamie cheating on him with his business rival Tristan.
It hurt so much that for months he suffered emotionally and swore never to entertain any woman in his life, neither did he think about finding love again.
He refused to think about relationships or give any female a chance to know him.
He channel all his energy to work, not giving space for dates because he believed Love will lead to pain,and pain brings sufferings that will just ruin you.
Maybe someday he would open his heart to love, not to anyone but to the right person.
Everyday is always a typical day for Daylan, Friday included. Paper works occupied his table, he's the CEO of the company doesn't mean he is free from work or should become lazy.
He believed it was his hardwork and focus that made him who he is today, so he is not going to relax because there is still a lot he has to achieve. Shifting his eyeglasses he continue typing in his computer with all seriousness.
He barely have a life outside his work and company, Anthony his best friend and also his assistant do tell him he's not getting any younger, he needs to find love again, marry and have kids.
Typical Daylan shrugged and continue living his successful but lonely life.
Faye was running late for the interview she has this morning after applying to numerous companies for a job.
No doubt she is beautiful, smart and hardworking. Her mum own a small coffee shop few miles away from their home with just one person assisting her.
With no dad, she knew her mum is doing everything possible to make her and her siblings well catered for.
Faye started working at an early age to be able to complete her education and also help out at home, she does menial jobs like delivery person, washing dishes in restaurants, scrubbing floors just to get money to assist her mum.
Sage group of companies called her for an interview, but she was already late.
On getting to the gigantic building she took a deep breath and walked in towards the receptionist.
"Good morning my name is Faye Smith. Iam here for the interview regarding the position of a secretary".
"Welcome! the  beautiful receptionist said with a smile.
"please go in there are others there, someone will call you, and interview you." She said showing the way,
" Thank you! Faye replied returning the smile.
Good luck!
Faye heart was beating fast, as she sees those who went in coming out with long faces, she hoped they will like her and give her the job, because she badly needed it.
She was getting nervous when the person before her was called in, she knew she was prepared for the interview but what if she flop?
What if she is not who they wanted?
What if she could not answer the questions thrown at her?
Beads of sweat formed on her forehead, she silently begged her star to favour her because this is her only hope.
"Faye Smith?" A beautiful middle-aged woman called looking around, but gave a beautiful smile when she saw Faye walking towards her.
"Yes'
"Please come in for your interview"
She walked in slowly into the room, Faye swallowed hard when six pairs of eyes landed on her as she opened the door.
************
That morning Daylan was not in a good mood, first he missed an important call, secondly his house maid burnt his breakfast, and his car broke down when he was coming to work, and on getting to work the documents he gave his typist to work on there were numerous errors.
Everything was getting on his nerves, he likes everything to be perfect and all the ideas to pull through.
There are some days when the thought of having a family comes to his mind, he has a big mansion that can house dozens of children, and a fortune that can feed them until they are aged.
No! He doesn't have time for that, family can wait until whenever he feels he's ready.
He angrily told the typist to type the documents again after pointing out the errors, while the interview was going on downstairs.
Few hours later, Faye came out of the building smiling and happy.
She got the job!
An high paid job in one of the top leading company in the country.
"I got the job!" She screamed, not minding those giving her a surprise stare, while some smiled and congratulated her.
Quickly she called her mum to break the good news to her, then her bestfriend Maya who squealed on the phone congratulating her Bestie.
They later agreed to celebrate in the newest bar in town, 'Farouz'"`
}, {
    title: 'He\'s My Ruthless Billionaire',
    author: 'Lexi',
    cover: 'https://img.moonshadowreader.com/book-cover/79194207702_1610520055.png',
    introduction: `"Why... Why me??" I cried as he dragged me to the room and threw me on the bed, but my head collided with the sharp side of it.
"Because it's your fate!" he yelled.
"But... Who are you to decide my fate!?!" I finally decided to talk back to him.
"So, you've got the guts to talk back?" He chuckled and a smirk formed on his lips. "Then let's get this straight today, love," he said.
"W-what? N-no!" I said but his expressions changed to rage as he narrowed his eyebrows.
He came near me and took my face in his hands.
"L-leave me... P-please" I said.
"I want to crumble you like this, and I will, you just wait and watch, Hailey," he said and left the room."`,
    firstChpater: `"Why... Why me??" I cried as he dragged me to the room and threw me on the bed, but my head collided with the sharp side of it.
"Because it's your fate!" he yelled.
"But... Who are you to decide my fate!?!" I finally decided to talk back to him.
"So, you've got the guts to talk back?" He chuckled and a smirk formed on his lips. "Then let's get this straight today, love," he said.
"W-what? N-no!" I said but his expressions changed to rage as he narrowed his eyebrows.
He came near me and took my face in his hands.
"L-leave me... P-please" I said.
"I want to crumble you like this, and I will, you just wait and watch, Hailey," he said and left the room."`
}, {
    title: 'The Rejected Mate',
    author: 'Slade.Angel',
    cover: 'https://img.moonshadowreader.com/book-cover/90872750173_1622203279.jpeg',
    introduction: `For a werewolf to find a mate is very difficult. There are many obstacles standing in their paths - not the least of which is that they are surrounded by humans that they have a hard time relating to and that have a hard time relating to them! Many werewolves go a long time without finding a mate. But how did the ones that did find one do it?`,
    firstChpater: `"I can see them, what are these fools thinking? Do they think they can escape me?
Are they kidding me? no one has ever escaped from Slade and me yet.
“Angel wait for us!” Mark shouted. He is my new Alpha now.
I just smiled at him. He might be powerful but I’m faster than him.
I will not let these rogues escape over my watch. I will arrest them and give them the appropriate punishment they deserve.
‘Come on Angel, what are you waiting for?’ Slade asked. I can feel her power inside of me. She wants to fight already.
‘Nothing! you are in a hurry again. Don’t worry, they can’t escape us.’
I jumped in front of them.
“Where do you think you are going guys?” I asked as I looked at them one by one.
“It’s none of your business, leave our path now.” Rogue #1 said while growling at me.
“Are you ordering me rogue? If you don't want to die right away, close your disgusting mouth.” I said.
“Who are you to tell us that? You are just one of those Alpha's puppies.”
They are getting into my nerves. Do I look like a puppy to them?
"I'm your worst nightmare darling." I said before I attacked them.
Yes, I'm not the old pathetic, weak, Ugly Chubby, little clumsy girl anymore. It's been two years since I left Moon Crescent Pack and after six months of travelling, Stallion Mark Regor found and saved me in the forest and let me join His pack, which is the Wolf Fang Pack. He is so nice and He is like an older brother to me. He took good care of me. For him, I took the place of his late sister who was killed by the rogues. That is why no rogues can enter our territory and coming out alive.
They growl at me again and transform into their wolf. Those disgusting smell of rogues hit my nose and their bloodshot eyes looking straight to me, like they want to eat me.
What do they think, they will scare me with those disgusting looks? Mark molds me to become the strongest female wolf in his pack. Even Mark can't stop me since we discover that I am gifted with many abilities and power. I can kill then in just one word. After three months staying in this pack, Slade shows herself. She’s a pure white wolf. Mark told me, that in legend, the only white wolf exist in our world was the one created by Moon Goddess herself in her world.
‘Can we finish them already? They are boring.’ Slade said.
‘So early? Can we play for a little bit?’
‘Not in the mood, plus Mark will surely scold us later.’ Slade said.
She’s right. Slade and Stallion is now best-friend. I don’t know how it happened, but she said that Mark’s wolf is somehow related to her.
‘Okay, no fun today.’ I said.
They approached at once and circled me. Suddenly, the other one attacked but I already anticipated their moves so I grabbed him by the nape and threw him at his two companions. When I saw that they were hurt, I did not hesitate, I pulled the other one and punched him in the face. I took the other one and hit him with the one I punched earlier, I pulled his tail and I throw him on the nearest tree and they lost consciousness.
To be fair to them, I did not transform into my wolf.
A few minutes later, Mark arrived. He is blessed with teleportation power like me but he didn’t like using it. It take tons of his energy to used it, that’s why he avoided using it ever since.
"Angel! I told you to wait for us." Mark said when they arrived.
“Sorry Mark, Slade was just excited to chase them.”
“Alpha! finally we caught up with you too.” Kayden said. He’s the Beta of Wolf fang pack.
“Hi Kaden!” I waved at him.
“I'm still talking to you Angel, I already told you to wait for us and avoid fighting alone. What if something bad happened to you? what am I going to do?” Mark said.
I can feel how important I am to him and it makes me feel warm inside my heart.
'I hope Michael is like that too.' I said to Slade.
'You miss Him?' Slade
'I don't know.'
"I'm fine Mark, don't worr---- Ahhhhh!!!!" I feel it again. The pain, the fire inside my heart.
'That bastard!!!!! He’s doing it again.'
Whenever I feel heartache, Gabriel is the only one reason of it. As his mate, the moment we find our mate, our mate-bond is partially formed. Every time he has sex with someone or even just kiss and touch other girls, I will feel this kind of burn in my heart. The feel of betrayal. I remember, I have not yet accepted his rejection that's why I'm feeling this.
‘Why don't we accept his rejection yet?’ I asked Slade.
‘Because I want to say that in his own face in the right time.’ Slade.
"Angel what is happening to you?" Mark asked.
"I'm fine d-don-nt worry."
"That bastard, I'm going to kill Him." Kayden said. Kayden is not mated yet and I find Him very attractive and we both feel the same. He knows this kind of pain, I told Him already.
"What do you mean Kayden?" Mark asked. I look at Kayden but it was too late, Mark is using His Alpha power to get the information out of Mark.
"It's because of Angel’s mate. He is making out with other girl that’s why she feels a burning inside her heart. It makes her weak for a moment. Gladly, it doesn’t happened when she’s fighting with these rogues." Kayden explained.
“I've noticed that for a long time but I want you to tell me yourself. Is that why you left your pack?” Mark
I just nodded at what he said. No words wanted to come out of my mouth because I felt I was very guilty of Mark because I hid information about my mate from him.
“Okay now I know. Kayden, call back up here, take them to jail, then I will decide what I will do with them. You Angel, go back to the pack house and rest we have a meeting later. You need to be there. It’s important.” Mark said.
"Yes Alpha." I turned around to went home. I need to clean my body. I smell like rogues.
'What will be the agenda of our meeting later?' I asked Slade.
'I don't know. Let's go home. ' Slade answered.
From the moment I got here, Mark made a big effort to get Slade out, he was surprised when he once challenged her. We found out that Slade is a legendary wolf. She is color white and Her sapphire blue eyes shows Her pure blood. He said that people like me are rare, I also discovered my other abilities such as hearing, I can hear more than what Alphas can hear. I can heal my fellow wolf; I can also enter the link of another pack and most of all I can release energy that can obey even Alpha. Mark said I was gifted; we saw that our wolf is the same size and that was what surprised him the most. Only Alpha possesses such a large wolf.
It's been two years and I feel the pain and longing for my mate every day. Luckily, I have many people who loves me.
'We are happy now. I hope we can erase the past, even if it’s IMPOSSIBLE. '"`
}, {
    title: 'Mated to the Wolf King',
    author: 'Slade.Angel',
    cover: 'https://img.moonshadowreader.com/book-cover/90877267885_1622203262.jpeg',
    introduction: `I am nobody. My Dad hate me for the pain I cause him. Now I am bound to meet my mate and he will change my whole life and turn it into upside down.`,
    firstChpater: `"I have not seen him yet but I hear from my colleagues that he is someone you can't close with.
The palace rarely opens their doors for everyone. Tonight, the new king of werewolves will be crown after his first ever shift into his wolf. I cant wait to see it.
My Father strictly forbade me to leave the house and our pack land. He locked me in my room alone and threatened to punish me if I disobey him.
I do not know why my Father suddenly changed. He wasn't like this to me when Mother was still alive. Now, he's always mad at me without concrete reason and his punishment is beyond what I can imagine.
If only my mother were here, she would't let this happen to me. She will definitely dress me up and guide me all the time.
‘They can't do this to me. I need to see the new king first shift.’I said to myself.
I want to be free sometimes and do the things I want to do. I want to experience living this life according to my own will and escape from my father's rope.
I grab my bag and put some clothes that I need after I shift. I broke my glass window and jumped down from the fourth floor.
I know he will be angry with me and punish me when he finds out what I did, but I don't want to think about it right now.
I ran fast as soon as I shifted to my wolf.
I ignored every single thing that playing on my mind. If I want to start to have a life of my own, I need to have strength and will to do it without even thinking what my father will do to me.
I knew he will tie me again to the tree of sin where he buried my mother's body. That tree reminds me of the saddest event of my life, where everything started to changed.
I can feel the exhaustion filled my body as my legs become weak from a long run. The Kingdom is located at the center of all packs.
When I arrived at the celebration venue, I saw how many werewolves attended this event.
In front of the stage is the King who will be retiring after his son Zeus started to shift tonight.
I also see my father sitting in the first row with his beta and other Alphas with their betas and Lunas.
I shift back to my human form and put my clothes on.
Female-wolf can shift at their eighteenth birthday while Male-Wolf shifts in their twenty first birthday. But the royal blood is different, they shift when they become twenty-five but they are strong even in human form.
I smile when I see his form as a human. He is really handsome from what I heard from the female warriors in the pack, who was trained in the palace. His presence makes me want to run to him and kiss him so bad, but I remember his reputation. He’s not the type of guy who will just allow anyone near him even girls.
I shake my head. That thought is for my mate only.
I study him and he is not smiling at all. I wonder why.
Maybe he's just a snob. I said to myself.
"It is time my son, the moon is in its highest peak. The Moon Goddess blesses us with her presence." The King said.
When the light of the moon hit the golden stage, the new King started to shift. Everyone become silent for a moment, it’s looks like they were holding their breaths.
I can see every inch of his body sweating while the transformation is starting. He didn’t make any sound like he’s in pain. His bones started to move until the fur shows and he transformed into his wolf.
This is really breathtaking!!!!
The once handsome man changes into a huge midnight black wolf with a white half-moon mark in his forehead. That’s the birth mark of every King in their wolf form but the color is not always black. The previous king is a brown wolf.
They are all shouting ‘hail for the new King, Hail for King Zeus.’ And I joined them from here.
I'm still shouting when I see my father looks at my way. Our eyes met and from that moment I know that I'll be in big trouble when Father came back to the pack.
I shift back into my wolf and run as fast as I can back home.
I know he will punish me, he always will.
I reach the pack house in no time and lock the door of my room. I know there is no escape to my father’s fury even the closed door can't stop him from hurting me.
As if he heard my thoughts, the door harshly open and my furious looking Dad grab me by my hair and drag me outside, into the sinful tree where all my pack members already here and now witnessing this another embarassment.
How can they come here so fast? I thought they will still stay for a couple of minutes to congratulate the new king.
"How many times do I have to tell you that you must obey me? How many times Katharina?" He shouted while dragging me outside and tying me in the tree.
"Dad please I just want to see him shift that's all. No one saw me except you." I said while crying.
"I told you, you can't come to his coronation and yet you disobeyed me Katharina."
"Dad stops this. This will not help things to get better. Plus, there's nothing wrong for Katharina to be there. We are all needed to be there as respect for the king." My brother Sam shouted.
"You know what our Law is. I am the Alpha and your sister disobeyed me. She needs to be punished."
"Dad this is too much for her. The last time you punished her was because she talked to our visitors. She’s my sister and there’s nothing wrong with what she did today. We are all need to be there."
"Sammuel are you disobeying me too? Why do you always take her side?"
"No Dad, but I want you to realize that she's my sister and your daughter too! I'm taking her side because I see nothing wrong about what she did.
"Sam it’s okay, it's my fault, so let it be. I'm used to this." I said looking straight to my father's eyes. His eyes that was once full of love and happiness. His eyes who’s once looking at me like I’m the precious child on earth, is now holding the whip that is specially made for me. It has the stains of my blood from my previous punishment last week when I went out of the pack and pick some roses in the garden and then a man approached and talked to me.
I didn't know that it will make my Father's angry.
"This is for your disobedience Katharina and I hope this will be the last." And then I feel it. The kind of pain I've been receiving for almost eight years. Every whip in my back gives me the strength to not show any emotions.
I will not cry again for this stupid punishment. I've had enough of this. I can take every blow to make him feel better.
"I hope this time you will learn your lesson." Dad said.
"Really Dad? Or this time I should realize that I am the one who you blame for the my mother's death?" That's the last thing I said before I fainted.
*********
I saw a beautiful butterfly and I follow it deep in the forest. I'm all alone because Mom is preparing our food. I asked her if we could have picnic near the river and she always grant my wishes.
"Kath?" She said looking around.
I can't hear her because I'm too distracted with this beautiful butterfly until I reach the cave where the bear lives. Dad always told me to stay away from this area, yet I'm here.
"Ahhhhhhhhhh!" A bear suddenly appears and attacked me.
"Katharina!" Mom shouted.
"Mommy help me! Help me!" I shouted back.
All of the sudden my Mom shift in her beautiful brown wolf and protected me from the bear that trying to get me.
She knew she can't win because she's sick. In just a blink of an eye, the bear claws hit her chest and blood comes rushing.
"Kalisha!!!!!" My Dad shouted and all of the sudden a lot of pack warriors attack the bear and kill it.
"Kalisha don't leave me. I need the pack doctor now!" He shouted, but before the pack doctor arrived my Mom passed away.
My dad howled and I can feel his pain. He lost his mate. The half of his soul.
Dad asked me what happened and when I told him the reason. He looked at me and slapped me in the face. That is the day when Dad started to changed.
Every time I made mistake he will tie me in the sinful tree and whip me over and over until I pass out. That tree is where he buried my mother. It is where he would punish me every time he wanted to."`
}, {
    title: 'Sinful',
    author: 'Natashah',
    cover: 'https://img.moonshadowreader.com/book-cover/82734780566_1614060580.png',
    introduction: `"I'm very sure I'm allowed to fuck who so ever I want to fuck" I yelled at him, unaware of how close we were"No Cara, you only get to fuck me, I own you and this sweet pussy of yours" he said, sliding his fingers into my pussy.All this started because of that stupid threesome I agreed to.Sewa has been alone for most of her life, not because she couldn't have friends, no, she just enjoyed her own company and she loved peace and quiet. It lasted for a while until she came across the greatest disturbance Antonio Rivera, an Italian womanizer who took to liking her and her body.She can't seem to get him out of her mind after their first meeting and likewise.The man started to grow a strong sexual desire towards her until it became something more, something he dreaded.`,
    firstChpater: `"I adjusted my very tiny bikini against my rather large tits, wondering what monstrosity had led me to think that going to this new club opening would be fun.
I looked at the e-flier on my iPhone X one more time to be sure it's the right place I'm oh-so-sultry dressed for.
Dress code - Very revealing bikini or nothing at all.
It read.
It was the first nude club in this area and it was grand.
"Oh fuck it" I thought; as I had the ability to overthink and let me tell you, I'm a very indecisive person.
I looked at my reflection in the mirror one last time and dare I say, I looked banging hot.
The gold bikini I had on left almost nothing to the imagination. The top was almost too small but, doing a rather good job to hide my nipples and the panties, oh the panties! Made my already fat ass look even fatter and better.
I knew I was going to get lucky tonight.
I grabbed my brown kimono and my purse after slipping on my black 3inches stilettos. Luckily for me, the Uber I had ordered earlier was already here, so I locked my apartment and headed straight for my night of pure fun and pleasure.
-----------------------
The club was exactly as I had envisioned it to be, it was packed with a lot of beautiful women and men in all shapes, sizes and color, all dressed like me; dressed to kill.
I handed over my kimono to a guy at the entrance of the club, whose job was to collect the piece of clothing people used to cover their revealing bodies.
After successfully handing over my kimono, I strolled straight to the bar, I needed some alcohol in my system.
"Rum and coke" I ordered and the very built bartender nodded his head at me, not forgetting to look at my boobs before getting on with my drink. It didn't take long for me to get my drink and when it came, I downed it immediately, like a pro.
I turned around, looking at the packed dance floor, people grinding against each other as the dj played 'earned it'. From where I sat, I could even see a couple having sex on the dance floor; I guess they couldn't wait to go somewhere more private.
I moved to a set of cushions by the far left part of the club, the raging blue and green lights and people, making it hard to even get to the comfortable goodness but, I did.
There was this Arabian looking lady at the end of the cushion I sat on, she was too busy with her phone to even notice anyone was looking at her. She had a red bikini set against her tender porcelain skin, red is definitely her color; to her face, she clearly was blessed with good genes, and she knew it. I turned my face away from her because staring is 'rude' and fished out my phone, then I heard a gasp by my side. It was the red goddess.
"Oh my days, are those real?" She asked, looking at my beautiful pair with a surprised look on her face.
I smiled and nodded. "Wow, they're pretty. Can I feel em?" She asked and I nodded again, she came closer and felt my boobs "awesome" she cooed, she looked awed.
"You have a nice pair too" I told her and she laughed, thanking me.
"Aliya" she said smiling
"Sewa" I told her, smiling also
From that moment, I felt a friendship rising.
"I love the accent, where are you from?" She asked me
"Nigeria, what about you?" I asked back
"I'm Russian" she said, her accent thick.
We talked about almost everything and everyone,then we had more drinks and went to the dance floor to dance and show all the guys our sultry moves which we got a lot of howling and wolf whistles for. By now, I was so sure I was drunk and I could tell Aliya was too. Tired, we found our way back to the cushion we met and sprawled ourselves on it. Her phone that she managed to fix in her bikini top, started vibrating, she pulled it out and answered lazily.
"Huh?" She said into the phone. "Ugh, alright fine" she said and hung up.
"Gotta go?" I asked and she turned to me "sadly, I also have to find a sexy gir- Wait, you're really sexy, you should come home with me, I hope you don't mind a threesome?" She asked and I smiled "I don't mind at all" I told her.
"Okay good, because, this will be the only chance I'm gonna have to have those tits in my mouth" her vulgar words didn't faze me, it was her action that got me a little, she shifted my bikini top of my right boob aside, pinching my hard nipple, she flashed me a smile afterwards.
"Don't mind me, I'm straight" she felt the need to add "I wasn't judging. I am too" I told her and she grabbed my arm, pulling us both up.
"Last drink before we head out of here?" I asked and she nodded. We downed our drinks, collected our kimonos and left the club. Almost immediately though, a black car pulled up in front of us, I was too drunk to figure out the car type.
"Le Le"Aliya called, looking at the person in the car, he nodded at her and me then came out to help us get into the car. "Let me tell you about Antonio" Aliya said out of nowhere, holding unto my hands for dear life.
"He's super handsome and rich and also big and really hung" she giggled at the last part.
"He's not my boyfriend, I don't see myself ever dating him mostly because he was once my step brother, when our parents divorced, we became fuck buddies" she revealed and I felt like my eyes was going to pop out from it sockets. I was that surprised, I was.
"He'll like you though, you're sexy, he likes sexy" she said and smiled at me.
"We're almost there," she announced happily.
"I'll bet you 1 grand that there are people having an orgy in that house and Antonio is just there watching them, he's a real weirdo" she said and I laughed, I can't wait to meet this Antonio guy already. Just then, I felt the car slowing down and then finally stop.
Aliya opened the door and literally dragged me out of the car into the house, it was a very large house, it looked old but still beautiful. The chandelier that greeted us with its magnificent light was hanging from a high ceiling and the path we were on was a long corridor that looked like it had no end, so we walked a bit and then found the living room.
"What's Up bitches?"Aliya yelled before I even saw people. Funny enough, she wasn't wrong about the orgy part, there were about ten people in the room, five ladies and men, all connected to each other in one way or the other, everyone moaning loudly, the sound of bodies slapping against each other, making my insides tighten, I could feel my panties dampen.
Aliya ran over to the group as they acknowledged her presence and stopped at a very hot dude who I originally thought was Antonio, he was getting a a blow job from this really sexy girl, who was getting fucked by a seemingly hot guy. When she got to him, she took out her left boob from her bikini  and he bit her nipple, sucking gently.
"You should've given me your clit" the guy said and she laughed.
"Where's he?" She asked him, tucking her boob back in place.
"He just went to grab a drink" he told her, he's eyes darted to me, he gave me a once over, more like a million over, he let his eyes linger on my boobs and he didn't even care that I was watching him.
"Take your eyes off her, she's not yours, Phil" Aliya joked and he smiled.
"You always bring the best ones for him" this Phil said and Aliya rolled her eyes at him, pulling me away from the group of people having an orgy.
The way we were able to ignore them was beyond me, especially that Phil guy, he was literally getting a blow job and yet, he ran his mouth... and eyes.
"You weren't wrong about the orgy" I mentioned and she smiled "I know, they do this every Saturday" she let on and I nodded.
"Let's get a drink and then get fucked senseless" she yelled, leading me to the kitchen.
It was the biggest kitchen I had ever laid eyes on, painted white and had brown accessories. It was beautiful but I was too drunk to care.
Aliya served us tequila and we downed it immediately, letting the liquid burn every part it touched.
After our drinks, she grabbed my arm and led me to a flight of stairs that led to a hallway that had only one room, I'm guessing in there lies my fun for the night.
Aliya opened the door smiling, with me on her arm.
"I was thinking you'd never show" We heard a voice say, the room was dimly lit but, I could tell that there was a tall built man standing at the floor length mirror.
"Oh forgive me Anto, like you didn't run away when you heard the car come in" She mocked and he turned to us.
I think I died.
He was the most beautiful bearded person I had ever seen in my entire life, and he was watching me.
"Anto, this is Sewa and Sewa, this is the weirdo I told you about" she introduced informally and laughed.
"Let's have sex already, my pussy's yearning for some loving" I blurted out causing Aliya to laugh and Adonis Antonio give me an amused look.
"Come here" he motioned for me and before I could protest, my legs started moving to him.
He softly trailed his fingers from my shoulders down to my elbow, his face moved from mine to my boobs.
"Natural, I see," he said, his voice thick with an accent, an Italian accent I suppose.
Aliya came from behind me, losing my bikini top, letting my boobs free, while Antonio played with my nipples, making me feel hot all over.
I grabbed Mr. Gorgeous' face, pulled it closer to mine and placed my lips on his, he tensed up at first before kissing me right back, grabbing my ass in the process and making me bite his lips.
Aliya discarded her bikini and pulled me away from Antonio, placing her lips on mine while I played with her nipples, I broke the kiss and placed my lips on her right nipple, biting and sucking hard making her let out a moan.
"Bed" she moaned and we went to the bed. I was on top of her, sucking her left nipple and letting out small groans as I did.
I felt more pressure on the bed, meaning Antonio had joined us, he was behind me and before I knew what was happening, he tore my panties off me, immediately sliding a finger into my wet pussy.
I loved it.
I sneakily found Aliya's entrance and inserted my finger into her and she let out a loud moan.
Antonio lifted my ass higher and set himself lower so he could gain more access to my throbbing core, I felt his tongue dancing around my pussy lips, biting and sucking gently as I had don't to Aliya's nipple and boy does he know how to eat pussy.
Our moans filled the room.
I peeled myself from Aliya, turning to Antonio who's now bare, I looked at his dick and smirked, Aliya was not joking at all.
"Like what you see?" He asked, smirking also and I nodded, wrapping my hand around his dick. He was huge.
I smiled and placed my lips on his tip, teasing him while Aliya sat on his face.
I sucked him like my life depended on it, putting my hand and saliva to use, after some time, I felt his dick twitch in my mouth and I sucked faster, causing him to groan into Aliya's pussy. Then he has his release I had an option to spit but, I swallowed, loving the way his cum tasted.
Aliya moaned loudly, panting with her eyes closed, she was close to having an orgasm.
"Oh fuck yes" she let out as she found her release.
Antonio looked at me approvingly and smirked.
"You've earned first place to get my dick tonight" he said, he walked to the bedside table and took a condom, putting it on, he laid down back and the same position and we I smiled, climbing on top of him, my pussy directly over his dick, he held my hips, guiding me slowly as his dick filled me, he was really big.
He started slowly before increasing his pace, thrusting deeper and harder into me, making me moan louder.
"What's my name?" he asked in between thrusts
"Antonio" I yelled loudly and he found my g-spot, he figured out he hit my g-spot and fucked me harder, harder than I have ever been fucked and I've been fucked plenty.
"Yes, mmmm" i let out, biting my lips and running my fingers through my body, grabbing my boobs and pinching my nipples.
At this point, I forgot Aliya was here with us, this guys dick was just too magical and he knew how to cast spells.
Before I knew it, I groaned the loudest as I came. That was the first orgasm of many more to come."`
}];

export default books;