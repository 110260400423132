<template>
<div id="app">
    <div v-if="requestLoading.length" class="page-loading-container">
        <div class="page-loading">{{ loadingMsg }}</div>
    </div>
    <router-view/>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    created() {},
    computed: {
        ...mapState([
            'requestLoading'
        ]),
        loadingMsg() {
            const item = this.requestLoading[0];
            return typeof item === 'string' ? item : 'Loading…';
        }
    }
}

</script>

<style lang="less">
@import "./assets/less/app.less";

#app {
    min-height: 100%;
}
</style>
