import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/desktop/Index.vue';
import * as bekit from 'bekit';
const { Ua } = bekit.helper;
import store from '@/store';

Vue.use(VueRouter);

const commonRoutes = [{
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import( /* webpackChunkName: "forbidden" */ '../views/Forbidden.vue')
}, {
    path: '/refresh',
    name: 'Refresh',
    component: () => import( /* webpackChunkName: "refresh" */ '../views/Refresh.vue')
}];
const platformRoutes = {
    desktop: {
        path: '/',
        component: () => import( /* webpackChunkName: "desktop-layout" */ '../layouts/Desktop.vue'),
        children: [{
            path: '/',
            alias: '/index',
            name: 'DesktopIndex',
            component: () => import( /* webpackChunkName: "desktop-index" */ '../views/desktop/Index.vue'),
            meta: {
                tab: 'home'
            }
        }, {
            path: '/writer-benefits',
            name: 'DesktopWriterBenefits',
            component: () => import( /* webpackChunkName: "desktop-writer-benefits" */ '../views/desktop/WriterBenefits.vue'),
            meta: {
                tab: 'writer-benefits'
            }
        }],
        meta: {
            register: true
        }
    },
    mobile: {
        path: '/',
        component: () => import( /* webpackChunkName: "mobile-layout" */ '../layouts/Mobile.vue'),
        children: [{
            path: '/',
            alias: '/index',
            name: 'MobileIndex',
            component: () => import( /* webpackChunkName: "mobile-index" */ '../views/mobile/Index.vue'),
        }],
        meta: {
            register: false
        }
    }
};
// 为防止 pc、mobile 有相同的路由，先注册的路由生效，后注册路由无效
const platforms = Ua.isMob() ? ['mobile', 'desktop'] : ['desktop', 'mobile'];
const registerPlatformRoutes = [];
platforms.forEach(platform => {
    if (!platformRoutes[platform].meta.register) return false;
    registerPlatformRoutes.push(platformRoutes[platform]);
})
let routes = commonRoutes.concat(registerPlatformRoutes);
// 在结尾增加 404 页面
// routes.push({
//     path: '*',
//     component: () => import( /* webpackChunkName: "forbidden" */ '../views/Forbidden.vue')
// });

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    // 无效路由处理
    if (!to.matched.length) {
        return next({
            path: '/',
            replace: true
        });
    }
    next();
});

export default router;