const authors = [{
    nickName: 'LTMarshall',
    avatar: 'https://img.moonshadowreader.com/writer-avatar/88806136513_1620131003.jpg',
    introduction: 'Author Leanne Marshall is an aspiring romance writer from the UK who is fast scaling the charts as an Author. She has a passion for telling stories filled with dramatic twists and turns, deep emotional issues and gripping characters.'
}, {
    nickName: 'MimiNeinei',
    avatar: 'https://img.moonshadowreader.com/writer-avatar/82592429709_1618798201.jpg',
    introduction: 'I want to be your favorite author.'
}, {
    nickName: 'Ani',
    avatar: 'https://img.moonshadowreader.com/writer-avatar/90973962397_1622309409.jpg',
    introduction: 'I\'m a plain girl who likes to write more reasonable stories'
}];

export default authors;