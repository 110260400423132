import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        requestLoading: [],
    },
    mutations: {
        addRequestLoading(state, item) {
            state.requestLoading.push(item);
        },
        reduceRequestLoading(state, item) {
            state.requestLoading.shift();
        }
    },
    actions: {},
    modules: {}
});