<template>
<div class="page-content">
    <div class="text-center page-jumbotron">
        <div class="g-section-content">
            <img src="@/assets/img/index/blue.svg" class="bg-blue" />
            <img src="@/assets/img/index/pink.svg" class="bg-pink" />
            <h1 class="text-title page-jumbotron-title">10,000+ High-Quality Novels</h1>
            <div class="page-jumbotron-desc">We provide a reliable platform for creators. Twenty authors have published their works on friver.</div>
        </div>
    </div>
    <div class="g-section-content">
        <div class="kc-wrap-container">
            <div class="kc-wrap">
                <div v-for="(book, index) in books" :key="index" class="kc-item" @click="changeBook(index)">
                    <img :src="book.cover" class="kc-item-img" />
                </div>
            </div>
        </div>
    </div>
    <div class="current-book-intro">
        <div class="g-section-content">
            <div class="book-title">{{ books[currentBook].title }}</div>
            <div class="book-author">{{ books[currentBook].author }}</div>
            <div class="book-intro">
                <ellipsis-text class="p-base"
                    :content="books[currentBook].introduction + '\n' + books[currentBook].firstChpater"
                    :line="9"
                    :isHtml="true"
                    :trigger-more="false">
                </ellipsis-text>
            </div>
        </div>
    </div>
    <div class="author-privileges">
        <div class="flex flex-justify g-section-content">
            <div v-for="(author, index) in authors" :key="index" class="flex-item-1 privilege-item">
                <div class="item-img-container">
                    <img :src="author.avatar" class="item-img" />
                </div>
                <div class="item-text">
                    <div class="text-title item-text-title">{{ author.nickName }}</div>
                    <div class="item-text-desc">
                        <div class="p-base">{{ author.introduction }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import EllipsisText from "vue-ellipsis-text";
import '@/assets/js/killercarousel.js';
import books from './books.js';
import authors from './authors.js';

export default {
  name: 'DesktopIndex',
  data() {
      return {
          kcInstance: null,
          currentBook: 0,
          books,
          authors
      }
  },
  mounted() {
      this.init();
  },
  methods: {
      init() {
          this.initKc();
      },
      initKc() {
          let _this = this;
          $('.kc-wrap').KillerCarousel({
              frontItemIndex: _this.currentBook,
              infiniteLoop: true,
              spacing2d: 142,
              renderer3d: null,
              horizon: 'top: 100%',
              itemAlign: 'bottom',
              renderer2d: 'render2dCarousel',
              useMouseWheel: false,
              width: 1300,
              fadeEdgeItems: true,
              navigationHorizontalPos: 'right: 100px',
              animStopCallback() {
                  let index = _this.kcInstance.getFrontItemIndex();
                  _this.changeBook(index);
              }
          });
          this.kcInstance = $('.kc-wrap').data('KillerCarousel');
          this.removeWarning();
      },
      removeWarning() {
          let timer = setInterval(() => {
              let warningDom = $('.kc-horizon').next('div');
              if (!warningDom || !warningDom.attr('style')) return;
              warningDom.attr("style","display: none");
              warningDom.text('');
              clearInterval(timer);
              timer = null;
          }, 100);
      },
      changeBook(index) {
          this.currentBook = index;
      }
  },
  components: {
      EllipsisText
  }
}
</script>

<style lang="less" scoped>
@import (reference) "~base.less";
@import "~@/assets/less/killercarousel.less";

.page-content {
    overflow: hidden;
}

.page-jumbotron {
    position: relative;
    padding: 208px 120px 134px;

    &-title {
        font-size: 36px;
    }
    &-desc {
        margin-top: 24px;
    }
    .bg-blue, .bg-pink {
        position: absolute;
        width: 800px;
        height: 800px;
    }
    .bg-blue {
        top: 0;
        left: 0;
        transform: translateY(-50%);
    }
    .bg-pink {
        right: 0;
        bottom: 0;
        transform: translate(20%, 50%);
    }
}
.kc-wrap-container {
    overflow: visible;
}
.current-book-intro {
    margin-top: 12px;
    color: @color-white;
    .g-section-content {
        padding: 40px 120px 48px;
        background-color: #1d1e2b;
    }
    .book-title {
        text-align: center;
        font-size: @fontsize-lg;
    }
    .book-author {
        margin-top: 20px;
        text-align: center;
        font-size: @fontsize-sm;
        color: @color-text;
    }
    .book-intro {
        margin-top: 24px;
        /deep/ .ellipsis-content {
            white-space: pre-wrap;
            color: @color-white;
        }
    }
}
.author-privileges {
    text-align: center;
    .g-section-content {
        padding: 150px 120px;
    }
    .privilege-item {
        .item-img-container {
            width: 100px;
            height: $width;
            display: inline-block;
            .item-img {
                border-radius: 16px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .item-text {
            margin-top: 46px;
            &-title {
                font-size: @fontsize-lg;
            }
            &-desc {
                margin-top: 16px;
                color: @color-text-title;
            }
        }
    }
}
</style>
